// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.webmapp-modalgiftcoin {
  padding: 16px;
}
.webmapp-modalgiftcoin .webmapp-modalgiftcoin-closebtn {
  font-size: 24px;
}
.webmapp-modalgiftcoin .webmapp-modalgiftcoin-title {
  font-weight: var(--wm-font-weight-bold);
  font-size: var(--wm-font-xxxlg);
  color: var(--wm-color-dark);
  padding-top: 5px;
  display: block;
}
.webmapp-modalgiftcoin .webmapp-modalgiftcoin-image {
  padding: 40px;
}
.webmapp-modalgiftcoin .webmapp-modalgiftcoin-info-main {
  padding-top: 30px;
  padding-left: 30px;
  display: inline-block;
  font-size: var(--wm-font-md);
  color: var(--wm-color-dark);
}
.webmapp-modalgiftcoin .wwebmapp-modalgiftcoin-buttoncontainer {
  position: absolute;
  bottom: 60px;
  left: 0%;
  width: 100%;
  text-align: center;
  padding: 0 20px;
}
.webmapp-modalgiftcoin .wwebmapp-modalgiftcoin-buttoncontainer .wwebmapp-modalgiftcoin-button {
  --border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal-gift-coins/modal-gift-coins.component.scss"],"names":[],"mappings":"AAEA;EAII,aAAA;AAJJ;AAMI;EACI,eAAA;AAJR;AAOI;EACI,uCAAA;EACA,+BAAA;EACA,2BAAA;EACA,gBAAA;EACA,cAAA;AALR;AAQI;EACI,aAAA;AANR;AASI;EACI,iBAAA;EACA,kBAAA;EACA,qBAAA;EACA,4BAAA;EACA,2BAAA;AAPR;AAUI;EACI,kBAAA;EACA,YAAA;EACA,QAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;AARR;AAUQ;EACI,qBAAA;AARZ","sourcesContent":["@import \"src/theme/mixins.scss\";\n\n.webmapp-modalgiftcoin {\n    //   width: 100%;\n    //   height: 100%;\n    //   --background: var(--wm-color-secondary);\n    padding: 16px;\n\n    .webmapp-modalgiftcoin-closebtn {\n        font-size: 24px;\n    }\n\n    .webmapp-modalgiftcoin-title {\n        font-weight: var(--wm-font-weight-bold);\n        font-size: var(--wm-font-xxxlg);\n        color: var(--wm-color-dark);\n        padding-top: 5px;\n        display: block;\n    }\n\n    .webmapp-modalgiftcoin-image {\n        padding: 40px;\n    }\n\n    .webmapp-modalgiftcoin-info-main {\n        padding-top: 30px;\n        padding-left: 30px;\n        display: inline-block;\n        font-size: var(--wm-font-md);\n        color: var(--wm-color-dark);\n    }\n\n    .wwebmapp-modalgiftcoin-buttoncontainer {\n        position: absolute;\n        bottom: 60px;\n        left: 0%;\n        width: 100%;\n        text-align: center;\n        padding: 0 20px;\n\n        .wwebmapp-modalgiftcoin-button {\n            --border-radius: 20px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
