// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.webmapp-popover {
  padding: 10px;
  background-color: var(--wm-color-dark);
  color: var(--wm-color-light);
  font-size: var(--wm-font-md);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/generic-popover/generic-popover.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sCAAA;EACA,4BAAA;EACA,4BAAA;AACJ","sourcesContent":[".webmapp-popover{\n    padding: 10px;\n    background-color: var(--wm-color-dark);\n    color: var(--wm-color-light);\n    font-size: var(--wm-font-md);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
