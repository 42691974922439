// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-modal-header .wm-modal-header-container {
  display: block;
  width: 100%;
}
wm-modal-header .wm-modal-header-container .wm-modal-header-toolbar .wm-modal-header-dismiss {
  font-size: var(--wm-font-sm);
  --background: var(--wm-color-light);
  --color: var(--wm-color-dark);
  --box-shadow: none;
  --background-activated: var(--wm-color-dark);
  --background-activated-opacity: 0.25;
  --background-focused: var(--wm-color-light);
  --background-focused-opacity: 0;
  --background-hover: var(--wm-color-light);
  --background-hover-opacity: 0;
}
wm-modal-header .wm-modal-header-container .wm-modal-header-toolbar .wm-modal-header-title {
  font-size: var(--wm-font-md);
}
wm-modal-header .wm-modal-header-container .wm-modal-header-close {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/modal-header/modal-header.component.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,WAAA;AAAJ;AAEM;EACE,4BAAA;EACA,mCAAA;EACA,6BAAA;EACA,kBAAA;EACA,4CAAA;EACA,oCAAA;EACA,2CAAA;EACA,+BAAA;EACA,yCAAA;EACA,6BAAA;AAAR;AAGM;EACE,4BAAA;AADR;AAKI;EACE,eAAA;AAHN","sourcesContent":["wm-modal-header{\n  .wm-modal-header-container {\n    display: block;\n    width: 100%;\n    .wm-modal-header-toolbar {\n      .wm-modal-header-dismiss {\n        font-size: var(--wm-font-sm);\n        --background: var(--wm-color-light);\n        --color: var(--wm-color-dark);\n        --box-shadow: none;\n        --background-activated: var(--wm-color-dark);\n        --background-activated-opacity: 0.25;\n        --background-focused: var(--wm-color-light);\n        --background-focused-opacity: 0;\n        --background-hover: var(--wm-color-light);\n        --background-hover-opacity: 0;\n      }\n\n      .wm-modal-header-title {\n        font-size: var(--wm-font-md);\n      }\n    }\n\n    .wm-modal-header-close{\n      font-size: 14px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
