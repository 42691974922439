// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.webmapp-modalphotosingle-title {
  text-align: center;
}

.webmapp-modalphotosingle-item {
  padding: 15px;
}
.webmapp-modalphotosingle-item .webmapp-modalphotosingle-item-photo {
  max-height: 70vh;
  padding: 10px;
}
.webmapp-modalphotosingle-item .webmapp-modalphotosingle-item-photo::part(image) {
  max-height: 70vh;
  object-fit: cover;
  border-radius: 15px;
  margin: auto;
}
.webmapp-modalphotosingle-item .webmapp-modalphotosingle-item-inputwrapper {
  padding: 0px 15px 0px 0px;
}
.webmapp-modalphotosingle-item .webmapp-modalphotosingle-item-inputwrapper .webmapp-modalphotosingle-item-label {
  font-weight: var(--wm-font-weight-bold);
}
.webmapp-modalphotosingle-item .webmapp-modalphotosingle-item-inputwrapper .webmapp-modalphotosingle-item-input {
  border-bottom: 1px solid var(--wm-color-secondary);
}

.webmapp-modalphotosingle-footer {
  text-align: center;
}
.webmapp-modalphotosingle-footer .webmapp-modalphotosingle-savebtn {
  --border-radius: 22px;
  --padding-start: 100px;
  --padding-end: 100px;
  font-weight: var(--wm-font-weight-bold);
  margin: 20px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 32px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modalphotos/modal-photo-single/modal-photo-single.component.scss","webpack://./src/theme/mixins.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;AADJ;;AAGA;EACI,aAAA;AAAJ;AACI;EACI,gBAAA;EACA,aAAA;AACR;AACQ;EACI,gBAAA;EACA,iBAAA;EAEA,mBAAA;EACA,YAAA;AAAZ;AAGI;EACI,yBAAA;AADR;AAEI;EACI,uCAAA;AAAR;AAEI;EACI,kDAAA;AAAR;;AAKA;EACI,kBAAA;AAFJ;AAGI;EACI,qBAAA;EACA,sBAAA;EACA,oBAAA;EACA,uCAAA;EACA,YAAA;ECpCN,2CAAA;EDsCM,mBAAA;AADR","sourcesContent":["@import \"src/theme/mixins.scss\";\n\n.webmapp-modalphotosingle-title {\n    text-align: center;\n}\n.webmapp-modalphotosingle-item {\n    padding: 15px;\n    .webmapp-modalphotosingle-item-photo {\n        max-height: 70vh;  \n        padding: 10px;     \n            \n        &::part(image) {\n            max-height: 70vh; \n            object-fit: cover;            \n            // max-width: 90%;\n            border-radius: 15px;\n            margin: auto;\n        }\n    }\n    .webmapp-modalphotosingle-item-inputwrapper {\n        padding: 0px 15px 0px 0px;\n    .webmapp-modalphotosingle-item-label {\n        font-weight: var(--wm-font-weight-bold);\n    }\n    .webmapp-modalphotosingle-item-input {\n        border-bottom: 1px solid var(--wm-color-secondary);\n    }\n    }\n}\n\n.webmapp-modalphotosingle-footer {\n    text-align: center;\n    .webmapp-modalphotosingle-savebtn {\n        --border-radius: 22px;\n        --padding-start: 100px;\n        --padding-end: 100px;\n        font-weight: var(--wm-font-weight-bold);\n        margin: 20px;\n        @include box-shadow-light;\n        border-radius: 32px;\n    }\n}\n","@mixin box-shadow-light {\n  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);\n}\n\n@mixin box-shadow-dark {\n  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
