// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.webmapp-settings-list .webmapp-settings-button {
  --padding-start: 15px;
  --padding-end: 15px;
}

.webmapp-settings-version {
  color: var(--wm-color-darkgrey);
}

.webmapp-profile-links {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/settings/settings.component.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;EACA,mBAAA;AAAJ;;AAIA;EACE,+BAAA;AADF;;AAGA;EACE,aAAA;EACA,6BAAA;EACA,cAAA;AAAF","sourcesContent":[".webmapp-settings-list {\n  .webmapp-settings-button {\n    --padding-start: 15px;\n    --padding-end: 15px;\n  }\n}\n\n.webmapp-settings-version {\n  color: var(--wm-color-darkgrey);\n}\n.webmapp-profile-links {\n  display: flex;\n  justify-content: space-evenly;\n  margin-top: 2%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
