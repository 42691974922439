// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-map-controls .layer-button {
  position: relative !important;
  color: black;
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  font-size: 20px;
  top: 2%;
  right: 2%;
  height: 40px;
  display: block;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}
wm-map-controls .layer-content {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.8);
  top: calc(2% + 60px + constant(safe-area-inset-top));
  top: calc(2% + 60px + env(safe-area-inset-top));
  right: 2%;
  z-index: 1;
  height: 50vh;
}
wm-map-controls ion-list {
  background-color: rgba(255, 255, 255, 0.8) !important;
}
wm-map-controls ion-content {
  width: 200px;
}
wm-map-controls ion-item {
  --background: rgba(\$color: white, \$alpha: 0.8) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/map-core/src/components/controls/controls.map.scss"],"names":[],"mappings":"AACE;EACE,6BAAA;EACA,YAAA;EACA,0CAAA;EACA,eAAA;EACA,eAAA;EACA,OAAA;EACA,SAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;EACA,eAAA;EACA,UAAA;AAAJ;AAEE;EACE,eAAA;EACA,0CAAA;EAEA,oDAAA;EACA,+CAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;AADJ;AAGE;EACE,qDAAA;AADJ;AAGE;EACE,YAAA;AADJ;AAGE;EACE,yDAAA;AADJ","sourcesContent":["wm-map-controls {\n  .layer-button {\n    position: relative !important;\n    color: black;\n    background-color: rgba($color: white, $alpha: 0.8);\n    position: fixed;\n    font-size: 20px;\n    top: 2%;\n    right: 2%;\n    height: 40px;\n    display: block;\n    padding: 10px;\n    cursor: pointer;\n    z-index: 1;\n  }\n  .layer-content {\n    position: fixed;\n    background-color: rgba($color: white, $alpha: 0.8);\n\n    top: calc(2% + 60px + constant(safe-area-inset-top));\n    top: calc(2% + 60px + env(safe-area-inset-top));\n    right: 2%;\n    z-index: 1;\n    height: calc(50vh);\n  }\n  ion-list {\n    background-color: rgba($color: white, $alpha: 0.8) !important;\n  }\n  ion-content {\n    width: 200px;\n  }\n  ion-item {\n    --background: rgba($color: white, $alpha: 0.8) !important;\n  }\n\n  @media only screen and (max-width: 480px) {\n    .layer-content {\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
