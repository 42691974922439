// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.webmapp-pageroute-tabdescription-description {
  padding: 15px;
  margin-bottom: 10px;
}

.webmapp-pageroute-tabdescription-title {
  padding-left: 15px;
  font-weight: var(--wm-font-weight-bold);
  font-size: var(--wm-font-lg);
}

.webmapp-pageroute-tabdescription-photoslider {
  padding: 20px 0;
  overflow: visible;
}
.webmapp-pageroute-tabdescription-photoslider .webmapp-pageroute-tabdescription-photo {
  height: 150px;
  width: 250px;
}
.webmapp-pageroute-tabdescription-photoslider .webmapp-pageroute-tabdescription-photo::part(image) {
  border-radius: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/tab-description/tab-description.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,uCAAA;EACA,4BAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;AACF;AACE;EACE,aAAA;EACA,YAAA;AACJ;AAAI;EACE,mBAAA;AAEN","sourcesContent":[".webmapp-pageroute-tabdescription-description {\n  padding: 15px;\n  margin-bottom: 10px;\n}\n\n.webmapp-pageroute-tabdescription-title {\n  padding-left: 15px;\n  font-weight: var(--wm-font-weight-bold);\n  font-size: var(--wm-font-lg);\n}\n\n.webmapp-pageroute-tabdescription-photoslider {\n  padding: 20px 0;\n  overflow: visible;\n\n  .webmapp-pageroute-tabdescription-photo {\n    height: 150px;\n    width: 250px;\n    &::part(image) {\n      border-radius: 15px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
