// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-map-save-custom-track-controls .layer-button {
  color: black;
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  font-size: 20px;
  top: 2%;
  right: 2%;
  height: 40px;
  display: block;
  padding: 10px;
  cursor: pointer;
}
wm-map-save-custom-track-controls .layer-content {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.8);
  top: calc(2% + 50px);
  right: 2%;
  height: 100px;
}
wm-map-save-custom-track-controls ion-item {
  --background: rgba(\$color: white, \$alpha: 0.8) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/map-core/src/components/save-custom-track/save-custom-track.map.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,0CAAA;EACA,eAAA;EACA,eAAA;EACA,OAAA;EACA,SAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;EACA,eAAA;AAAJ;AAEE;EACE,eAAA;EACA,0CAAA;EACA,oBAAA;EACA,SAAA;EACA,aAAA;AAAJ;AAGE;EACE,yDAAA;AADJ","sourcesContent":["wm-map-save-custom-track-controls {\n  .layer-button {\n    color: black;\n    background-color: rgba($color: white, $alpha: 0.8);\n    position: fixed;\n    font-size: 20px;\n    top: 2%;\n    right: 2%;\n    height: 40px;\n    display: block;\n    padding: 10px;\n    cursor: pointer;\n  }\n  .layer-content {\n    position: fixed;\n    background-color: rgba($color: white, $alpha: 0.8);\n    top: calc(2% + 50px);\n    right: 2%;\n    height: 100px;\n  }\n\n  ion-item {\n    --background: rgba($color: white, $alpha: 0.8) !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
