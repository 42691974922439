// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.webmapp-btn-orientation-fab {
  margin: 0;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.webmapp-btn-orientation-fab.webmapp-btn-orientation-fab-light {
  --background: var(--wm-color-light);
  --color: var(--wm-color-dark);
  --background-activated: var(--wm-color-dark);
  --background-activated-opacity: 0.25;
  --background-focused: var(--wm-color-light);
  --background-focused-opacity: 0;
  --background-hover: var(--wm-color-light);
  --background-hover-opacity: 0;
}

.webmapp-btn-orientation-icon {
  font-size: 28px;
}

.path2, .path4 {
  margin-left: 1px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/shared/buttons/btn-orientation/btn-orientation.component.scss","webpack://./src/theme/mixins.scss"],"names":[],"mappings":"AAEA;EACE,SAAA;ECFA,2CAAA;EDIA,mBAAA;AADF;AAGE;EACE,mCAAA;EACA,6BAAA;EACA,4CAAA;EACA,oCAAA;EACA,2CAAA;EACA,+BAAA;EACA,yCAAA;EACA,6BAAA;AADJ;;AAKA;EACI,eAAA;AAFJ;;AAKA;EACE,gBAAA;AAFF","sourcesContent":["@import \"src/theme/mixins.scss\";\n\n.webmapp-btn-orientation-fab {\n  margin: 0;\n  @include box-shadow-light;\n  border-radius: 20px;\n\n  &.webmapp-btn-orientation-fab-light {\n    --background: var(--wm-color-light);\n    --color: var(--wm-color-dark);\n    --background-activated: var(--wm-color-dark);\n    --background-activated-opacity: 0.25;\n    --background-focused: var(--wm-color-light);\n    --background-focused-opacity: 0;\n    --background-hover: var(--wm-color-light);\n    --background-hover-opacity: 0;\n  }\n}\n\n.webmapp-btn-orientation-icon{\n    font-size: 28px;\n}\n\n.path2,.path4{\n  margin-left:1px;\n}","@mixin box-shadow-light {\n  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);\n}\n\n@mixin box-shadow-dark {\n  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
