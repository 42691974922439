// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-profile-records .wm-profile-records-title {
  padding: 5px 15px 0 15px;
  margin: 0;
  font-weight: var(--wm-font-weight-bold);
  font-size: var(--wm-font-lg);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/profile/profile-records/profile-records.component.scss"],"names":[],"mappings":"AACE;EACI,wBAAA;EACA,SAAA;EACA,uCAAA;EACA,4BAAA;AAAN","sourcesContent":["wm-profile-records{\n  .wm-profile-records-title {\n      padding: 5px 15px 0 15px;\n      margin: 0;\n      font-weight: var(--wm-font-weight-bold);\n      font-size: var(--wm-font-lg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
