// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    webmapp-title {
    padding: 3%;
    font-size: 21px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    display:block;
    font-family: var(--wm-font-family-content, inherit);
  }
  `, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/box/title/title.component.ts"],"names":[],"mappings":";IACI;IACA,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,mDAAmD;EACrD","sourcesContent":["\n    webmapp-title {\n    padding: 3%;\n    font-size: 21px;\n    font-weight: 700;\n    line-height: 25px;\n    letter-spacing: 0em;\n    text-align: left;\n    display:block;\n    font-family: var(--wm-font-family-content, inherit);\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
