// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `webmapp-filter {
  z-index: 1;
  display: block;
}
webmapp-filter ion-button,
webmapp-filter ion-segment {
  --background: var(--wm-color-primary);
}

ion-segment ion-segment-button {
  --background-hover: var(--wm-color-primary);
  --color-checked: var(--wm-color-primary);
}

div svg {
  height: 200%;
}

.chip-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
}
.chip-list ion-chip {
  order: 0;
  flex: 1 1 auto;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
}
.chip-list ion-chip ion-label {
  order: 0;
  flex: 1 1 auto;
  align-self: stretch;
}
.chip-list ion-chip ion-icon,
.chip-list ion-chip i {
  order: 0;
  flex: 0 1 auto;
  align-self: stretch;
}
.chip-list ion-chip img {
  position: relative;
  height: 25px;
  display: block;
  margin-left: -7px;
  margin-top: -4px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/shared/btn-filter/btn-filter.component.scss"],"names":[],"mappings":"AAAA;EAKE,UAAA;EACA,cAAA;AAHF;AAFE;;EAEE,qCAAA;AAIJ;;AAEE;EACE,2CAAA;EACA,wCAAA;AACJ;;AAGE;EACE,YAAA;AAAJ;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,8BAAA;EACA,sBAAA;EACA,oBAAA;AAAF;AACE;EACE,QAAA;EACA,cAAA;EACA,mBAAA;EAEA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,8BAAA;EACA,sBAAA;EACA,oBAAA;AAAJ;AACI;EACE,QAAA;EACA,cAAA;EACA,mBAAA;AACN;AACI;;EAEE,QAAA;EACA,cAAA;EACA,mBAAA;AACN;AACI;EACE,kBAAA;EACA,YAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;AACN","sourcesContent":["webmapp-filter {\n  ion-button,\n  ion-segment {\n    --background: var(--wm-color-primary);\n  }\n  z-index: 1;\n  display: block;\n}\nion-segment {\n  ion-segment-button {\n    --background-hover: var(--wm-color-primary);\n    --color-checked: var(--wm-color-primary);\n  }\n}\ndiv {\n  svg {\n    height: 200%;\n  }\n}\n.chip-list {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  align-content: stretch;\n  align-items: stretch;\n  ion-chip {\n    order: 0;\n    flex: 1 1 auto;\n    align-self: stretch;\n\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    align-content: stretch;\n    align-items: stretch;\n    ion-label {\n      order: 0;\n      flex: 1 1 auto;\n      align-self: stretch;\n    }\n    ion-icon,\n    i {\n      order: 0;\n      flex: 0 1 auto;\n      align-self: stretch;\n    }\n    img {\n      position: relative;\n      height: 25px;\n      display: block;\n      margin-left: -7px;\n      margin-top: -4px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
