// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-track-audio {
  margin-bottom: 36px;
  display: block;
}
wm-track-audio audio {
  width: -webkit-fill-available;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/track-audio/track-audio.component.scss"],"names":[],"mappings":"AAAA;EAIE,mBAAA;EACA,cAAA;AAFF;AAFE;EACE,6BAAA;AAIJ","sourcesContent":["wm-track-audio {\n  audio {\n    width: -webkit-fill-available;\n  }\n  margin-bottom: 36px;\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
