// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-slider-filter ion-range::part(tick) {
  background: #a2d2ff;
}
wm-slider-filter ion-range::part(tick-active) {
  background: #bde0fe;
}
wm-slider-filter ion-range::part(pin) {
  color: #fff;
  transform: scale(1.01);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  gap: 10px;
  position: absolute;
  width: 31px;
  height: 22px;
  left: calc(50% - 15.5px - 0.5px);
  top: -14px;
  background: #4d4d4d;
  border-radius: 4px;
}
wm-slider-filter ion-range::part(pin)::before {
  content: none;
}
wm-slider-filter ion-range::part(knob) {
  background: var(--wm-color-primary);
}
wm-slider-filter ion-range::part(bar) {
  background: #f4f4f4;
}
wm-slider-filter ion-range::part(bar-active) {
  background: var(--wm-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/filters/slider-filter/slider-filter.component.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;AAAJ;AAGE;EACE,mBAAA;AADJ;AAIE;EACE,WAAA;EAEA,sBAAA;EAEA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,SAAA;EAEA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,gCAAA;EACA,UAAA;EAEA,mBAAA;EACA,kBAAA;AANJ;AASE;EACE,aAAA;AAPJ;AAUE;EACE,mCAAA;AARJ;AAWE;EACE,mBAAA;AATJ;AAYE;EACE,mCAAA;AAVJ","sourcesContent":["wm-slider-filter {\n  ion-range::part(tick) {\n    background: #a2d2ff;\n  }\n\n  ion-range::part(tick-active) {\n    background: #bde0fe;\n  }\n\n  ion-range::part(pin) {\n    color: #fff;\n\n    transform: scale(1.01);\n\n    box-sizing: border-box;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    padding: 0px 8px;\n    gap: 10px;\n\n    position: absolute;\n    width: 31px;\n    height: 22px;\n    left: calc(50% - 31px / 2 - 0.5px);\n    top: -14px;\n\n    background: #4d4d4d;\n    border-radius: 4px;\n  }\n\n  ion-range::part(pin)::before {\n    content: none;\n  }\n\n  ion-range::part(knob) {\n    background: var(--wm-color-primary);\n  }\n\n  ion-range::part(bar) {\n    background: #f4f4f4;\n  }\n\n  ion-range::part(bar-active) {\n    background: var(--wm-color-primary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
