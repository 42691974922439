// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.webmapp-formfield-item {
  padding: 22px 15px 10px 22px;
  overflow: visible;
}
.webmapp-formfield-item.webmapp-formfield-item-noicon {
  padding: 0px;
}
.webmapp-formfield-item.compiled {
  --border-color: var(--wm-color-primary);
}
.webmapp-formfield-item.error {
  --border-color: var(--wm-color-red);
}
.webmapp-formfield-item:focus-within {
  --border-color: var(--wm-color-dark);
}
.webmapp-formfield-item .webmapp-formfield-label {
  font-weight: var(--wm-font-weight-bold);
}
.webmapp-formfield-item .webmapp-formfield-label .webmapp-formfield-label-secondary {
  font-weight: var(--wm-font-weight-light);
}
.webmapp-formfield-item .webmapp-formfield-label .webmapp-formfield-label-required {
  color: var(--ion-color-danger);
}
.webmapp-formfield-item .webmapp-formfield-label.webmapp-formfield-labelphoto {
  height: 30px;
  top: 0px;
  position: absolute;
}
.webmapp-formfield-item .webmapp-formfield-icon {
  position: absolute;
  left: -28px;
  top: 10px;
  color: var(--wm-color-grey);
}

.webmapp-formfield-error {
  padding: 0px 25px 8px 41px;
  color: var(--ion-color-danger);
  display: inline-block;
}

.webmapp-formfield-content {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/shared/form-field/form-field.component.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,iBAAA;AACF;AAAE;EACE,YAAA;AAEJ;AACE;EACE,uCAAA;AACJ;AAGE;EACE,mCAAA;AADJ;AAIE;EACE,oCAAA;AAFJ;AAOE;EACE,uCAAA;AALJ;AAMI;EACE,wCAAA;AAJN;AAMI;EACE,8BAAA;AAJN;AAMI;EACE,YAAA;EACA,QAAA;EACA,kBAAA;AAJN;AAQE;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,2BAAA;AANJ;;AAqBA;EACE,0BAAA;EACA,8BAAA;EACA,qBAAA;AAlBF;;AAqBA;EACE,WAAA;AAlBF","sourcesContent":[".webmapp-formfield-item {\n  padding: 22px 15px 10px 22px;\n  overflow: visible;\n  &.webmapp-formfield-item-noicon{\n    padding: 0px;\n  }\n\n  &.compiled {\n    --border-color: var(--wm-color-primary);\n  }\n\n\n  &.error {\n    --border-color: var(--wm-color-red);\n  }\n\n  &:focus-within {\n    --border-color: var(--wm-color-dark);\n  }\n\n  // border: 1px solid var(--ion-color-danger);\n\n  .webmapp-formfield-label {\n    font-weight: var(--wm-font-weight-bold);\n    .webmapp-formfield-label-secondary {\n      font-weight: var(--wm-font-weight-light);\n    }\n    .webmapp-formfield-label-required {\n      color: var(--ion-color-danger);\n    }\n    &.webmapp-formfield-labelphoto {\n      height: 30px;\n      top: 0px;\n      position: absolute;\n    }\n  }\n\n  .webmapp-formfield-icon {\n    position: absolute;\n    left: -28px;\n    top: 10px;\n    color: var(--wm-color-grey);\n\n    // &.primary {\n    //   color: var(--wm-color-primary);\n    // }\n\n    // &.secondary {\n    //   color: var(--wm-color-secondary);\n    // }\n\n    // &.tertiary {\n    //   color: var(--wm-color-tertiary);\n    // }\n  }\n}\n.webmapp-formfield-error {\n  padding: 0px 25px 8px 41px;\n  color: var(--ion-color-danger);\n  display: inline-block;\n}\n\n.webmapp-formfield-content {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
