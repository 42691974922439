// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-tab-detail .webmapp-pageroute-tabdetail-chart {
  text-align: center;
  width: 100%;
  display: block;
  padding: 0 10px;
}
wm-tab-detail .webmapp-pageroute-tabdetail-label {
  color: var(--wm-color-dark);
}
wm-tab-detail .webmapp-pageroute-tabdetail-icon {
  color: var(--wm-color-primary);
  padding: 0 5px;
}
wm-tab-detail .webmapp-pageroute-tabdetail-value {
  color: var(--wm-color-dark);
  font-weight: var(--wm-font-weight-bold);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/tab-detail/tab-detail.component.scss"],"names":[],"mappings":"AACE;EAEE,kBAAA;EACA,WAAA;EACA,cAAA;EACA,eAAA;AADJ;AAIE;EACE,2BAAA;AAFJ;AAKE;EACE,8BAAA;EACA,cAAA;AAHJ;AAME;EACE,2BAAA;EACA,uCAAA;AAJJ","sourcesContent":["wm-tab-detail {\n  .webmapp-pageroute-tabdetail-chart {\n    // height: 200px;\n    text-align: center;\n    width: 100%;\n    display: block;\n    padding: 0 10px;\n  }\n\n  .webmapp-pageroute-tabdetail-label {\n    color: var(--wm-color-dark);\n  }\n\n  .webmapp-pageroute-tabdetail-icon {\n    color: var(--wm-color-primary);\n    padding: 0 5px;\n  }\n\n  .webmapp-pageroute-tabdetail-value {\n    color: var(--wm-color-dark);\n    font-weight: var(--wm-font-weight-bold);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
