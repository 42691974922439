export const wmPR = {
  'skitouring': 'Esqui de montanha',
  'horse': 'A cavalo',
  'walking': 'Caminhada',
  'running': 'Corrida',
  'cycling': 'Ciclismo',
  'poi_type': 'Pontos de interesse',
  'where': 'Locais',
  'lang': 'Idioma',
  'langPlaceholder': 'Selecionar',
  'from': 'Início',
  'to': 'Fim',
  'ascent': 'Elevação +',
  'descent': 'Elevação -',
  'distance': 'Distância',
  'ele_from': 'Altitude de partida',
  'ele_max': 'Altitude máxima',
  'ele_min': 'Altitude mínima',
  'ele_to': 'Altitude de chegada',
  'difficulty': 'Dificuldade',
  'duration_forward': 'Duração da ida',
  'duration_backward': 'Duração da volta',
  'Filtri': 'Filtros',
  'Cerca': 'Pesquisar',
  'tracce': 'Trilhas',
  'hiking': 'Caminhada',
  'Escursionismo': 'Caminhada',
  'escursionismo': 'Caminhada',
  'i miei percorsi': 'Minhas rotas',
  'metri': 'Metros',
  'Vedi su OpenStreetMap': 'Ver no OpenStreetMap',
  'Descrizione Audio': 'Descrição de áudio',
  'il tuo browser non supporta gli elementi audio': 'Seu navegador não suporta elementos de áudio.',
  'stampa': 'Imprimir',
  'Difficoltà': 'Dificuldade',
  'Questo percorso non è accessibile': 'Este caminho não é acessível',
  'Descrizione': 'Descrição',
  'Punto di partenza': 'Ponto de partida',
  'Punto di arrivo': 'Ponto de chegada',
  'Dettagli tecnici': 'Detalhes técnicos',
  'Attività': 'Atividades',
  'Come arrivare': 'Como chegar',
  'Bici muscolari': 'Bicicletas comuns',
  'Bici elettriche': 'Bicicletas elétricas',
  'Mezzi pubblici': 'Transporte público',
  'Auto Propria': 'Carro próprio',
  'Punti di interesse': 'Pontos de interesse',
  'Collegamenti esterni': 'Links externos',
  'Downloads': 'Downloads',
  'edit geohub': 'Editar geohub',
  'NCC': 'NCC',
  'Filtri attivi': 'Filtros ativos',
  'Reset filtri': 'Redefinir filtros',
  'Torna alla home': 'Voltar para a página inicial',
  'Caricamento in corso.': 'Carregando.',
  'Spiacenti non ci sono risultati con questi criteri di ricerca.':
    'Desculpe, não há resultados com estes critérios de pesquisa',
  'Bentornato!': 'Boas-vindas!',
  'Accedi con le tue credenziali per continuare.': 'Faça login com as suas credenciais para continuar.',
  'E-mail': 'E-mail',
  'L\'e-mail è obbligatoria': 'O e-mail é obrigatório',
  'Hai inserito un\'e-mail non valida': 'Introduziu um e-mail inválido',
  'Password': 'Senha',
  'La password è obbligatoria': 'A senha é obrigatória',
  'Accedi': 'Conecte-se',
  'Hai dimenticato la password?': 'Esqueceu-se da sua senha?',
  'Accedi e scarica i tuoi percorsi preferiti': 'Aceda e descarregue os seus percorsi preferidos',
  'Puoi anche condividerli con i tuoi compagni di viaggio': 'Também pode dividir com os seus companheiros de viagem',
  'Registrati': 'Registrando',
  'Tracce scaricate': 'Rastreamento escaricado',
  'Utilizzo dati': 'Utilizo dados',
  'Registrazioni': 'Registros',
  'Photos': 'Photos',
  'Waypoint': 'Waypoint',
  "Nome": "Nome",
  "inserisci il tuo nome": "Insira o seu nome",
  "E' necessario inserire un nome": "Um nome deve ser introduzido",
  "Email": "Email",
  "E' necessario inserire una email": "É necessário introduzir um email",
  "L'email inserita non è un'email valida": "O e-mail é inválido",
  "Codice Fiscale": "Código Fiscal",
  "Perché ti chiediamo il CF?": "Porque pedimos o CF?",
  "Il codice fiscale inserito non è valido": "O código fiscal não é válido",
  "inserisci password": "introduza a senha",
  "E' necessario inserire la password": "Uma palavra-passe deve ser inserida",
  "Conferma password": "Confirme a sua senha",
  "Ripeti password": "Repita a palavra-passe",
  "E' necessario confermare la password": "A confirmação da palavra-passe é necessária",
  "La conferma non corrisponde con la password inserita sopra": "A confirmação não corresponde à palavra-chave acima introduzida",
  "Cliccando su \"Registrati\" accetti la nostra": "Ao clicar em \"Registar\" aceita os nossos",
  "e i": "e i",
  "Termini e condizioni": "Termos e Condições",
  "Errore durante la registrazione dell'utente": "Erro durante o registo do utilizador",
  "Registrazione in corso": "Registo em andamento",
  "Perchè ti chiediamo il Codice Fiscale?": "Porque lhe pedimos o Código Fiscal?",
  "Se sei Socia/o CAI inserisci il tuo CF al momento della registrazione. Per te il download delle tappe del Sentiero Italia CAI sarà automaticamente gratuito!":
    "Se é Membro CAI, insira o seu CF no momento da inscrição. Para si, o download dos passos do Sentiero Italia CAI será automaticamente gratuito!",
  "Origine dell'immagine": "Fonte da imagem",
  "Annulla": "Cancelar",
  "Dalla libreria": "Da biblioteca",
  "Scatta una foto": "Tire uma fotografia",
};
