// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-btn-track-recording ion-fab-button {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  --color: var(--wm-color-primary);
  --background: var(--wm-color-light);
  --background-activated: var(--wm-color-primary);
  --background-activated-opacity: 0.25;
  --background-focused: var(--wm-color-light);
  --background-focused-opacity: 0;
  --background-hover: var(--wm-color-light);
  --background-hover-opacity: 0;
}
wm-btn-track-recording ion-fab-button i {
  width: 42px;
  height: 42px;
  padding: 9px;
  font-size: var(--wm-font-icon);
}`, "",{"version":3,"sources":["webpack://./src/app/components/shared/buttons/btn-track-recording/btn-track-recording.component.scss","webpack://./src/theme/mixins.scss"],"names":[],"mappings":"AAGE;ECFA,2CAAA;EDKE,mBAAA;EAEA,gCAAA;EACA,mCAAA;EACA,+CAAA;EACA,oCAAA;EACA,2CAAA;EACA,+BAAA;EACA,yCAAA;EACA,6BAAA;AAJJ;AAKI;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,8BAAA;AAHN","sourcesContent":["@import 'src/theme/mixins.scss';\n\nwm-btn-track-recording {\n  ion-fab-button {\n    @include box-shadow-light;\n\n    border-radius: 20px;\n\n    --color: var(--wm-color-primary);\n    --background: var(--wm-color-light);\n    --background-activated: var(--wm-color-primary);\n    --background-activated-opacity: 0.25;\n    --background-focused: var(--wm-color-light);\n    --background-focused-opacity: 0;\n    --background-hover: var(--wm-color-light);\n    --background-hover-opacity: 0;\n    i {\n      width: 42px;\n      height: 42px;\n      padding: 9px;\n      font-size: var(--wm-font-icon);\n    }\n  }\n}\n","@mixin box-shadow-light {\n  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);\n}\n\n@mixin box-shadow-dark {\n  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
