// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-image-gallery .webmapp-pagepoi-info-body-photoslider {
  padding: 10px 0;
  overflow: visible;
}
wm-image-gallery .webmapp-pagepoi-info-body-photoslider .webmapp-pagepoi-info-body-photo {
  height: 150px !important;
  width: 250px !important;
}
wm-image-gallery .webmapp-pagepoi-info-body-photoslider .webmapp-pagepoi-info-body-photo img {
  height: 150px !important;
  width: 250px !important;
}
wm-image-gallery .webmapp-pagepoi-info-body-photoslider .webmapp-pagepoi-info-body-photo::part(image) {
  border-radius: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/shared/image-gallery/image-gallery.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,iBAAA;AAAJ;AACI;EACE,wBAAA;EACA,uBAAA;AACN;AAAM;EACE,wBAAA;EACA,uBAAA;AAER;AAAM;EACE,mBAAA;AAER","sourcesContent":["wm-image-gallery {\n  .webmapp-pagepoi-info-body-photoslider {\n    padding: 10px 0;\n    overflow: visible;\n    .webmapp-pagepoi-info-body-photo {\n      height: 150px !important;\n      width: 250px !important;\n      img {\n        height: 150px !important;\n        width: 250px !important;\n      }\n      &::part(image) {\n        border-radius: 15px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
