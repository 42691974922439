// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-box .wm-box {
  position: relative;
  margin: 10px;
  border-radius: 15px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 500ms;
  height: 176px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
}
wm-box .wm-box img {
  border-radius: 15px;
  object-fit: cover;
  align-self: stretch;
  height: 100%;
  width: 100%;
  filter: brightness(75%);
}
wm-box .wm-box .wm-box-title {
  font-size: 26px;
  font-family: var(--wm-font-family-content, inherit);
  font-style: normal;
  font-weight: 700;
  color: white;
  position: absolute;
  bottom: 0;
  padding: 6%;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  white-space: break-spaces;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/box/box/box.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,YAAA;EACA,mBAAA;EACA,8CAAA;EACA,qBAAA;EACA,aAAA;EACA,eAAA;EAEA,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,2BAAA;EACA,sBAAA;EACA,oBAAA;AADJ;AAGI;EACE,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,uBAAA;AADN;AAII;EACE,eAAA;EACA,mDAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EAEA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,yBAAA;AAHN","sourcesContent":["wm-box {\n  .wm-box {\n    position: relative;\n    margin: 10px;\n    border-radius: 15px;\n    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);\n    transition: all 500ms;\n    height: 176px;\n    cursor: pointer;\n\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    justify-content: flex-start;\n    align-content: stretch;\n    align-items: stretch;\n\n    img {\n      border-radius: 15px;\n      object-fit: cover;\n      align-self: stretch;\n      height: 100%;\n      width: 100%;\n      filter: brightness(75%);\n    }\n\n    .wm-box-title {\n      font-size: 26px;\n      font-family: var(--wm-font-family-content, inherit);\n      font-style: normal;\n      font-weight: 700;\n      color: white;\n      position: absolute;\n      bottom: 0;\n      padding: 6%;\n\n      line-height: 31px;\n      letter-spacing: 0em;\n      text-align: left;\n      white-space: break-spaces;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
