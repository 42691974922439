// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `webmapp-app-root ion-router-outlet {
  height: 100%;
  position: initial;
}
webmapp-app-root .webmapp-app-recordingbtn {
  position: absolute;
  left: 20px;
}
webmapp-app-root .webmapp-app-recordingbtn.webmapp-app-recordingbtn-low {
  bottom: 85px;
}
webmapp-app-root .webmapp-app-recordingbtn.webmapp-app-recordingbtn-middle {
  bottom: 124px;
}
webmapp-app-root .webmapp-app-recordingbtn.webmapp-app-recordingbtn-middlehigh {
  bottom: 155px;
}
webmapp-app-root .webmapp-app-recordingbtn.webmapp-app-recordingbtn-high {
  bottom: 415px;
}
webmapp-app-root .webmapp-app-recordingbtn.webmapp-app-recordingbtn-none {
  bottom: 180px;
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,iBAAA;AAAJ;AAEE;EACE,kBAAA;EACA,UAAA;AAAJ;AACI;EACE,YAAA;AACN;AACI;EACE,aAAA;AACN;AACI;EACE,aAAA;AACN;AACI;EACE,aAAA;AACN;AACI;EACE,aAAA;EACA,aAAA;AACN","sourcesContent":["webmapp-app-root {\n  ion-router-outlet {\n    height: 100%;\n    position: initial;\n  }\n  .webmapp-app-recordingbtn {\n    position: absolute;\n    left: 20px;\n    &.webmapp-app-recordingbtn-low {\n      bottom: 85px;\n    }\n    &.webmapp-app-recordingbtn-middle {\n      bottom: 124px;\n    }\n    &.webmapp-app-recordingbtn-middlehigh {\n      bottom: 155px;\n    }\n    &.webmapp-app-recordingbtn-high {\n      bottom: 415px;\n    }\n    &.webmapp-app-recordingbtn-none {\n      bottom: 180px;\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
