// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wm-slider-box-header {
  padding: 0 15px;
}

.wm-slider-box {
  overflow: visible;
}

.wm-slider-box-nodata {
  padding: 10px 20px;
  color: var(--wm-color-darkgrey);
  font-size: var(--wm-font-sm);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/box/slider-box/slider-box.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AACA;EACE,kBAAA;EACA,+BAAA;EACA,4BAAA;AAEF","sourcesContent":[".wm-slider-box-header {\n  padding: 0 15px;\n}\n\n.wm-slider-box {\n  overflow: visible;\n}\n.wm-slider-box-nodata {\n  padding: 10px 20px;\n  color: var(--wm-color-darkgrey);\n  font-size: var(--wm-font-sm);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
