// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-img {
  display: block;
  min-height: 100px;
  min-width: 100px;
  position: relative;
  border-radius: 15px;
}
wm-img .wm-img-spinner-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
wm-img .wm-img-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/shared/img/img.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;AACF;AACE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AAEE;EACE,iBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAAJ","sourcesContent":["wm-img {\n  display: block;\n  min-height: 100px;\n  min-width: 100px;\n  position: relative;\n  border-radius: 15px;\n\n  .wm-img-spinner-container {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  .wm-img-image {\n    object-fit: cover;\n    width: 100%;\n    height: 100%;\n    border-radius: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
