// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.webmapp-track-download-urls-item .webmapp-track-download-urls-item-label {
  font-family: var(--wm-font-family-content);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/track-download-urls/track-download-urls.component.scss"],"names":[],"mappings":"AACE;EACE,0CAAA;AAAJ","sourcesContent":[".webmapp-track-download-urls-item {\n  .webmapp-track-download-urls-item-label {\n    font-family: var(--wm-font-family-content);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
