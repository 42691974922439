// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-inner-component-html {
  padding: max(5%, env(safe-area-inset-top)) max(5%, env(safe-area-inset-right)) max(5%, env(safe-area-inset-bottom)) max(5%, env(safe-area-inset-left));
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-content: stretch;
  color: black;
  align-items: stretch;
}
wm-inner-component-html .dismiss {
  flex: 0 1 auto;
  align-self: auto;
  text-align: end;
  font-size: 40px;
  cursor: pointer;
}
wm-inner-component-html .content {
  overflow-y: auto;
  flex: 1 1 auto;
  align-self: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/inner-html/inner-html.component.scss"],"names":[],"mappings":"AAAA;EACE,sJAAA;EAEA,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,6BAAA;EACA,sBAAA;EACA,YAAA;EACA,oBAAA;AAAF;AACE;EACE,cAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;EACA,eAAA;AACJ;AACE;EACE,gBAAA;EAEA,cAAA;EACA,gBAAA;AACJ","sourcesContent":["wm-inner-component-html {\n  padding: max(5%, env(safe-area-inset-top)) max(5%, env(safe-area-inset-right))\n    max(5%, env(safe-area-inset-bottom)) max(5%, env(safe-area-inset-left));\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  justify-content: space-around;\n  align-content: stretch;\n  color: black;\n  align-items: stretch;\n  .dismiss {\n    flex: 0 1 auto;\n    align-self: auto;\n    text-align: end;\n    font-size: 40px;\n    cursor: pointer;\n  }\n  .content {\n    overflow-y: auto;\n    -ms-flex: 1 1 auto;\n    flex: 1 1 auto;\n    align-self: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
