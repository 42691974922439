export const wmDE = {
  'skitouring': 'Skibergsteigen',
  'horse': 'Zu Pferde',
  'walking': 'Wandern',
  'running': 'Laufen',
  'cycling': 'Radfahren',
  'poi_type': 'Interessante Orte',
  'where': 'Orte',
  'lang': 'Sprache',
  'langPlaceholder': 'Auswählen',
  'from': 'Start',
  'to': 'Ziel',
  'ascent': 'Anstieg +',
  'descent': 'Abstieg -',
  'distance': 'Entfernung',
  'ele_from': 'Start-Höhe',
  'ele_max': 'Max-Höhe',
  'ele_min': 'Min-Höhe',
  'ele_to': 'Ziel-Höhe',
  'difficulty': 'Schwierigkeit',
  'duration_forward': 'Hinweg-Dauer',
  'duration_backward': 'Rückweg-Dauer',
  'Filtri': 'Filter',
  'Cerca': 'Suche',
  'tracce': 'Strecken',
  'hiking': 'Wandern',
  'Escursionismo': 'Wandern',
  'escursionismo': 'Wandern',
  'i miei percorsi': 'Meine Strecken',
  'metri': 'Meter',
  'Vedi su OpenStreetMap': 'Auf OpenStreetMap ansehen',
  'Descrizione Audio': 'Audiobeschreibung',
  'il tuo browser non supporta gli elementi audio':
    'Ihr Browser unterstützt keine Audiowiedergabe.',
  'stampa': 'Drucken',
  'Difficoltà': 'Schwierigkeit',
  'Questo percorso non è accessibile': 'Diese Strecke ist nicht zugänglich',
  'Descrizione': 'Beschreibung',
  'Punto di partenza': 'Startpunkt',
  'Punto di arrivo': 'Zielpunkt',
  'Dettagli tecnici': 'Technische Details',
  'Attività': 'Aktivitäten',
  'Come arrivare': 'Anreise',
  'Bici muscolari': 'Muskelräder',
  'Bici elettriche': 'Elektroräder',
  'Mezzi pubblici': 'Öffentliche Verkehrsmittel',
  'Auto Propria': 'Eigenes Auto',
  'Punti di interesse': 'Interessante Orte',
  'Collegamenti esterni': 'Externe Links',
  'Downloads': 'Downloads',
  'edit geohub': 'Geohub bearbeiten',
  'NCC': 'NCC',
  'Filtri attivi': 'Aktive Filter',
  'Reset filtri': 'Filter zurücksetzen',
  'Torna alla home': 'Zurück zur Startseite',
  'Caricamento in corso.': 'Wird geladen.',
  'Spiacenti non ci sono risultati con questi criteri di ricerca.':
    'Leider gibt es keine Ergebnisse mit diesen Suchkriterien',
  'Bentornato!': 'Willkommen zurück!',
  'Accedi con le tue credenziali per continuare.': 'Melden Sie sich mit Ihren Zugangsdaten an, um fortzufahren.',
  'E-mail': 'E-mail',
  'L\'e-mail è obbligatoria': 'E-Mail ist erforderlich',
  'Hai inserito un\'e-mail non valida': 'Sie haben eine ungültige E-Mail eingegeben',
  'Password': 'Passwort',
  'La password è obbligatoria': 'Passwort erforderlich ist',
  'Accedi': 'Login',
  'Hai dimenticato la password?': 'Sie haben Ihr Passwort vergessen?',
  'Accedi e scarica i tuoi percorsi preferiti': 'Greifen Sie auf Ihre Lieblingsrouten zu und laden Sie sie herunter',
  'Puoi anche condividerli con i tuoi compagni di viaggio': 'Sie können sie auch mit Ihren Reisebegleitern teilen',
  'Registrati': 'Melden Sie sich an',
  'Tracce scaricate': 'Heruntergeladene Titel',
  'Utilizzo dati': 'Datennutzung',
  'Registrazioni': 'Aufzeichnungen',
  'Photos': 'Photos',
  'Waypoint': 'Waypoint',
  "Nome": "Name",
  "inserisci il tuo nome": "Geben Sie Ihren Namen ein",
  "E' necessario inserire un nome": "Es muss ein Name eingegeben werden",
  "Email": "Email",
  "E' necessario inserire una email": "Es ist notwendig, eine E-Mail einzugeben",
  "L'email inserita non è un'email valida": "Die E-Mail ist ungültig",
  "Codice Fiscale": "Abgabenordnung",
  "Perché ti chiediamo il CF?": "Warum fragen wir Sie nach der Abgabenordnung?",
  "Il codice fiscale inserito non è valido": "Der Steuercode ist ungültig",
  "inserisci password": "Passwort eingeben",
  "E' necessario inserire la password": "Es muss ein Passwort eingegeben werden",
  "Conferma password": "Passwort bestätigen",
  "Ripeti password": "Wiederholen Sie das Passwort",
  "E' necessario confermare la password": "Eine Passwortbestätigung ist erforderlich",
  "La conferma non corrisponde con la password inserita sopra": "Die Bestätigung stimmt nicht mit dem oben eingegebenen Passwort überein",
  "Cliccando su \"Registrati\" accetti la nostra": "Mit dem Klick auf \"Registrieren\" akzeptieren Sie unsere",
  "e i": "e i",
  "Termini e condizioni": "Geschäftsbedingungen",
  "Errore durante la registrazione dell'utente": "Fehler bei der Benutzerregistrierung",
  "Registrazione in corso": "Registrierung läuft",
  "Perchè ti chiediamo il Codice Fiscale?": "Warum fragen wir Sie nach der Abgabenordnung?",
  "Se sei Socia/o CAI inserisci il tuo CF al momento della registrazione. Per te il download delle tappe del Sentiero Italia CAI sarà automaticamente gratuito!":
    "Wenn Sie CAI-Mitglied sind, geben Sie bei der Registrierung Ihre CF ein. Für Sie ist der Download der Etappen des Sentiero Italia CAI automatisch kostenlos!",
  "Origine dell'immagine": "Bildquelle",
  "Annulla": "Stornieren",
  "Dalla libreria": "Aus der Bibliothek",
  "Scatta una foto": "Ein Foto machen",
};
