// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.webmapp-pagemap-recordingbtn-button {
  --border-radius: 15px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.webmapp-pagemap-recordingbtn-button .webmapp-pagemap-recordingbtn-content {
  padding: 3px 10px;
  font-size: var(--wm-font-xxsm);
  text-align: left;
}
.webmapp-pagemap-recordingbtn-button .webmapp-pagemap-recordingbtn-content .webmapp-pagemap-recordingbtn-content-text {
  padding: 3px 0;
}
.webmapp-pagemap-recordingbtn-button .webmapp-pagemap-recordingbtn-content .webmapp-pagemap-recordingbtn-content-time {
  font-weight: var(--wm-font-weight-bold);
}`, "",{"version":3,"sources":["webpack://./src/app/components/shared/recording-btn/recording-btn.component.scss","webpack://./src/theme/mixins.scss"],"names":[],"mappings":"AAEE;EACE,qBAAA;ECFF,2CAAA;EDIE,mBAAA;AADJ;AAII;EACE,iBAAA;EACA,8BAAA;EACA,gBAAA;AAFN;AAIS;EACG,cAAA;AAFZ;AAKM;EACE,uCAAA;AAHR","sourcesContent":["  @import \"src/theme/mixins.scss\";\n\n  .webmapp-pagemap-recordingbtn-button {\n    --border-radius: 15px;\n    @include box-shadow-light;\n    border-radius: 20px;\n\n    // border-radius: 32px;\n    .webmapp-pagemap-recordingbtn-content {\n      padding: 3px 10px;\n      font-size: var(--wm-font-xxsm);\n      text-align: left;\n\n         .webmapp-pagemap-recordingbtn-content-text {\n            padding: 3px 0;\n         }\n\n      .webmapp-pagemap-recordingbtn-content-time {\n        font-weight: var(--wm-font-weight-bold);\n      }\n    }\n  }\n","@mixin box-shadow-light {\n  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);\n}\n\n@mixin box-shadow-dark {\n  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
