// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-track-edges .wm-track-edges-from {
  align-items: flex-start;
}
wm-track-edges .wm-track-edges-to {
  text-align: left;
}
wm-track-edges .wm-track-edges-from > :first-child,
wm-track-edges .wm-track-edges-to > :first-child {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
}
wm-track-edges .wm-track-edges-to > * {
  text-align: right;
}
wm-track-edges .wm-track-edges-start-end-icons {
  height: 25px;
  width: 25px;
}
wm-track-edges .wm-track-edges-start {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
}
wm-track-edges .wm-track-edges-start ion-grid {
  order: 0;
  flex: 0 0 auto;
  align-self: flex-start;
}
wm-track-edges .wm-track-edges-end {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
}
wm-track-edges .wm-track-edges-end ion-grid {
  order: 0;
  flex: 0 0 auto;
  align-self: flex-end;
}
wm-track-edges .wm-track-edges-prev,
wm-track-edges .wm-track-edges-next {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
}
wm-track-edges ion-icon {
  font-size: 4vh;
  color: #329f46;
}
wm-track-edges ion-fab-button {
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/track-edges/track-edges.component.scss"],"names":[],"mappings":"AACE;EACE,uBAAA;AAAJ;AAEE;EACE,gBAAA;AAAJ;AAEE;;EAEE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAAJ;AAEE;EACE,iBAAA;AAAJ;AAEE;EACE,YAAA;EACA,WAAA;AAAJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,uBAAA;AAAJ;AACI;EACE,QAAA;EACA,cAAA;EACA,sBAAA;AACN;AAEE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,uBAAA;AAAJ;AACI;EACE,QAAA;EACA,cAAA;EACA,oBAAA;AACN;AAEE;;EAEE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,uBAAA;AAAJ;AAEE;EACE,cAAA;EACA,cAAA;AAAJ;AAEE;EACE,kBAAA;AAAJ","sourcesContent":["wm-track-edges {\n  .wm-track-edges-from {\n    align-items: flex-start;\n  }\n  .wm-track-edges-to {\n    text-align: left;\n  }\n  .wm-track-edges-from > :first-child,\n  .wm-track-edges-to > :first-child {\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 20px;\n    letter-spacing: 0em;\n  }\n  .wm-track-edges-to > * {\n    text-align: right;\n  }\n  .wm-track-edges-start-end-icons {\n    height: 25px;\n    width: 25px;\n  }\n  .wm-track-edges-start {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-content: flex-start;\n    align-items: flex-start;\n    ion-grid {\n      order: 0;\n      flex: 0 0 auto;\n      align-self: flex-start;\n    }\n  }\n  .wm-track-edges-end {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-content: flex-start;\n    align-items: flex-start;\n    ion-grid {\n      order: 0;\n      flex: 0 0 auto;\n      align-self: flex-end;\n    }\n  }\n  .wm-track-edges-prev,\n  .wm-track-edges-next {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-content: flex-start;\n    align-items: flex-start;\n  }\n  ion-icon {\n    font-size: 4vh;\n    color: #329f46;\n  }\n  ion-fab-button {\n    margin-bottom: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
