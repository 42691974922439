export const wmEN = {
  'skitouring': 'Ski touring',
  'horse': 'On horseback',
  'walking': 'Walking',
  'running': 'Running',
  'cycling': 'Cycling',
  'poi_type': 'Points of Interest',
  'where': 'Places',
  'lang': 'Language',
  'langPlaceholder': 'Select',
  'from': 'Start',
  'to': 'Finish',
  'ascent': 'Slope +',
  'descent': 'Slope -',
  'distance': 'Distance',
  'ele_from': 'Starting Elevation',
  'ele_max': 'Max Elevation',
  'ele_min': 'Min Elevation',
  'ele_to': 'Finishing Elevation',
  'difficulty': 'Difficulty',
  'duration_forward': 'Outward Duration',
  'duration_backward': 'Return Duration',
  'Filtri': 'Filters',
  'Cerca': 'Search',
  'tracce': 'Tracks',
  'hiking': 'Hiking',
  'Escursionismo': 'Hiking',
  'escursionismo': 'Hiking',
  'i miei percorsi': 'Draw path',
  'Clicca sulla mappa per avviare la creazione di un percorso':
    'Click on the map to start creating a path',
  'metri': 'Meters',
  'Vedi su OpenStreetMap': 'See on OpenStreetMap',
  'Descrizione Audio': 'Audio Description',
  'il tuo browser non supporta gli elementi audio':
    'Your browser does not support the audio element.',
  'stampa': 'Print',
  'Difficoltà': 'Difficulty',
  'Questo percorso non è accessibile': 'This path is not accessible',
  'Descrizione': 'Description',
  'Punto di partenza': 'Start Point',
  'Punto di arrivo': 'Arrival Point',
  'Dettagli tecnici': 'Technical Details',
  'Attività': 'Activities',
  'Come arrivare': 'How to Get',
  'Bici muscolari': 'Muscle Bikes',
  'Bici elettriche': 'Electric Bikes',
  'Mezzi pubblici': 'Public Transport',
  'Auto Propria': 'Own Car',
  'Punti di interesse': 'Points of Interest',
  'Collegamenti esterni': 'External Links',
  'Downloads': 'Downloads',
  'edit geohub': 'Edit Geohub',
  'NCC': 'NCC',
  'Filtri attivi': 'Active Filters',
  'Reset filtri': 'Reset Filters',
  'Torna alla home': 'Back to Home',
  'Caricamento in corso.': 'Loading.',
  'Spiacenti non ci sono risultati con questi criteri di ricerca.':
    'Sorry, there are no results with these search criteria',
  'Lista percorsi personali': 'list of paths',
  'nome del percorso': 'name of path',
  'modifica': 'edit',
  'elimina': 'delete',
  'non hai percorsi': 'no paths',
  'al momento non hai nessun percorso salvato.': 'in this moment you dont have any saved paths.',
  'duration': 'Duration',
  'Bentornato!': 'Welcome!',
  'Accedi con le tue credenziali per continuare.': 'Log in with your credentials to continue.',
  'E-mail': 'E-mail',
  'L\'e-mail è obbligatoria': 'Email is required',
  'Hai inserito un\'e-mail non valida': 'You have entered an invalid email',
  'Password': 'Password',
  'La password è obbligatoria': 'Password is required',
  'Accedi': 'Login',
  'Hai dimenticato la password?': 'Did you forget your password?',
  'Accedi e scarica i tuoi percorsi preferiti': 'Access and download your favorite routes',
  'Puoi anche condividerli con i tuoi compagni di viaggio': 'You can also share them with your travel companions',
  'Registrati': 'Sign up',
  'Tracce scaricate': 'Downloaded tracks',
  'Utilizzo dati': 'Data usage',
  'Registrazioni': 'Records',
  'Photos': 'Photos',
  'Waypoint': 'Waypoint',
  "Nome": "Name",
  "inserisci il tuo nome": "Insert your name",
  "E' necessario inserire un nome": "A name must be entered",
  "Email": "Email",
  "E' necessario inserire una email": "It is necessary to insert an email",
  "L'email inserita non è un'email valida": "The email is invalid",
  "Codice Fiscale": "Fiscal Code",
  "Perché ti chiediamo il CF?": "Why do we ask you for the FC??",
  "Il codice fiscale inserito non è valido": "The fiscal code is not valid",
  "inserisci password": "enter password",
  "E' necessario inserire la password": "A password must be entered",
  "Conferma password": "Confirm password",
  "Ripeti password": "Repeat the password",
  "E' necessario confermare la password": "Password confirmation is required",
  "La conferma non corrisponde con la password inserita sopra": "The confirmation does not match the password entered above",
  "Cliccando su \"Registrati\" accetti la nostra": "By clicking on \"Register\" you accept our",
  "e i": "e i",
  "Termini e condizioni": "Terms and conditions",
  "Errore durante la registrazione dell'utente": "Error during user registration",
  "Registrazione in corso": "Registration in progress",
  "Perchè ti chiediamo il Codice Fiscale?": "Why do we ask you for the Fiscal Code?",
  "Se sei Socia/o CAI inserisci il tuo CF al momento della registrazione. Per te il download delle tappe del Sentiero Italia CAI sarà automaticamente gratuito!":
    "If you are a CAI Member, enter your CF when registering. For you, the download of the stages of the Sentiero Italia CAI will be automatically free!",
  "Origine dell'immagine": "Source of the image",
  "Annulla": "Cancel",
  "Dalla libreria": "From the library",
  "Scatta una foto": "Take a picture",
};
