// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.webmapp-gallery-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: var(--wm-color-dark);
}

.webmapp-gallery-closebtn {
  position: absolute;
  top: 30px;
  left: 0px;
  color: var(--wm-color-light);
  z-index: 101;
  padding: 15px;
  font-size: var(--wm-font-icon);
}

.webmapp-gallery-slider {
  left: 0;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.webmapp-gallery-image {
  min-width: 100%;
  min-height: 150px;
}

.webmapp-gallery-legend {
  position: absolute;
  bottom: 30px;
  left: 0px;
  width: 100%;
  text-align: center;
  display: block;
  color: var(--wm-color-light);
  z-index: 101;
  font-size: var(--wm-font-md);
}`, "",{"version":3,"sources":["webpack://./src/app/components/shared/gallery/gallery.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EAEA,sCAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,SAAA;EACA,SAAA;EACA,4BAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;AAAJ;;AAGA;EACI,OAAA;EACA,WAAA;EACA,kBAAA;EACA,QAAA;EAEA,2BAAA;AAAJ;;AAMA;EACI,eAAA;EACA,iBAAA;AAHJ;;AAMA;EACI,kBAAA;EACA,YAAA;EACA,SAAA;EACA,WAAA;EACA,kBAAA;EACA,cAAA;EACA,4BAAA;EACA,YAAA;EACA,4BAAA;AAHJ","sourcesContent":[".webmapp-gallery-backdrop {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 100;\n    // background-color: rgba(var(--wm-color-dark-rgb-trans));\n    background-color: var(--wm-color-dark);\n}\n\n.webmapp-gallery-closebtn {\n    position: absolute;\n    top: 30px;\n    left: 0px;\n    color: var(--wm-color-light);\n    z-index: 101;\n    padding: 15px;\n    font-size: var(--wm-font-icon);\n}\n\n.webmapp-gallery-slider {\n    left: 0;\n    width: 100%;\n    position: absolute;\n    top: 50%;\n    -ms-transform: translateY(-50%);\n    transform: translateY(-50%);\n}\n\n.webmapp-gallery-slide {\n}\n\n.webmapp-gallery-image {\n    min-width: 100%;\n    min-height: 150px;\n}\n\n.webmapp-gallery-legend{\n    position: absolute;\n    bottom: 30px;\n    left: 0px;\n    width: 100%;\n    text-align: center;\n    display: block;\n    color: var(--wm-color-light);\n    z-index: 101;\n    font-size: var(--wm-font-md);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
