// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-home-layer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  overflow-y: auto;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
}
wm-home-layer wm-img,
wm-home-layer .wm-description {
  margin: 10px;
}
wm-home-layer .wm-box-title {
  font-size: 26px;
  font-family: var(--wm-font-family-content, inherit);
  font-style: normal;
  font-weight: 700;
  color: white;
  position: absolute;
  bottom: 0;
  padding: 6%;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  white-space: break-spaces;
}
wm-home-layer .wm-description {
  max-height: 200px;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/home/home-layer/home-layer.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,2BAAA;EACA,oBAAA;EACA,sBAAA;EACA,gBAAA;EACA,wBAAA;EAAA,mBAAA;EACA,kBAAA;AACF;AAAE;;EAEE,YAAA;AAEJ;AAAE;EACE,eAAA;EACA,mDAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EAEA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,yBAAA;AACJ;AACE;EACE,iBAAA;EACA,cAAA;AACJ","sourcesContent":["wm-home-layer {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  justify-content: flex-start;\n  align-items: stretch;\n  align-content: stretch;\n  overflow-y: auto;\n  height: fit-content;\n  position: relative;\n  wm-img,\n  .wm-description {\n    margin: 10px;\n  }\n  .wm-box-title {\n    font-size: 26px;\n    font-family: var(--wm-font-family-content, inherit);\n    font-style: normal;\n    font-weight: 700;\n    color: white;\n    position: absolute;\n    bottom: 0;\n    padding: 6%;\n\n    line-height: 31px;\n    letter-spacing: 0em;\n    text-align: left;\n    white-space: break-spaces;\n  }\n  .wm-description {\n    max-height: 200px;\n    overflow: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
