export const wmFR = {
  'skitouring': 'Ski de randonnée',
  'horse': 'À cheval',
  'walking': 'Marche',
  'running': 'Course',
  'cycling': 'Cyclisme',
  'poi_type': "Points d'intérêt",
  'where': 'Lieux',
  'lang': 'Langue',
  'langPlaceholder': 'Sélectionner',
  'from': 'Départ',
  'to': 'Arrivée',
  'ascent': 'Dénivelé positif',
  'descent': 'Dénivelé négatif',
  'distance': 'Distance',
  'ele_from': 'Altitude de départ',
  'ele_max': 'Altitude maximale',
  'ele_min': 'Altitude minimale',
  'ele_to': "Altitude d'arrivée",
  'difficulty': 'Difficulté',
  'duration_forward': 'Durée aller',
  'duration_backward': 'Durée retour',
  'Filtri': 'Filtres',
  'Cerca': 'Recherche',
  'tracce': 'Parcours',
  'hiking': 'Randonnée',
  'Escursionismo': 'Randonnée',
  'escursionismo': 'Randonnée',
  'i miei percorsi': 'Mes parcours',
  'metri': 'Mètres',
  'Vedi su OpenStreetMap': 'Voir sur OpenStreetMap',
  'Descrizione Audio': 'Description audio',
  'il tuo browser non supporta gli elementi audio':
    'Votre navigateur ne prend pas en charge les éléments audio.',
  'stampa': 'Imprimer',
  'Difficoltà': 'Difficulté',
  'Questo percorso non è accessibile': "Ce parcours n'est pas accessible",
  'Descrizione': 'Description',
  'Punto di partenza': 'Point de départ',
  'Punto di arrivo': "Point d'arrivée",
  'Dettagli tecnici': 'Détails techniques',
  'Attività': 'Activités',
  'Come arrivare': 'Comment arriver',
  'Bici muscolari': 'Vélos musculaires',
  'Bici elettriche': 'Vélos électriques',
  'Mezzi pubblici': 'Transports en commun',
  'Auto Propria': 'Voiture personnelle',
  'Punti di interesse': "Points d'intérêt",
  'Collegamenti esterni': 'Liens externes',
  'Downloads': 'Téléchargements',
  'edit geohub': 'Modifier le geohub',
  'NCC': 'NCC',
  'Filtri attivi': 'Filtres actifs',
  'Reset filtri': 'Réinitialiser les filtres',
  'Torna alla home': "Retour à l'accueil",
  'Caricamento in corso.': 'Chargement.',
  'Spiacenti non ci sono risultati con questi criteri di ricerca.':
    "Désolé, il n'y a aucun résultat avec ces critères de recherche",
  'Bentornato!': 'Bienvenue!',
  'Accedi con le tue credenziali per continuare.': 'Connectez-vous avec vos identifiants pour continuer.',
  'E-mail': 'E-mail',
  'L\'e-mail è obbligatoria': 'L\'e-mail est requis',
  'Hai inserito un\'e-mail non valida': 'Vous avez entré un email invalide',
  'Password': 'Mot de passe',
  'La password è obbligatoria': 'Mot de passe requis',
  'Accedi': 'Connexion',
  'Hai dimenticato la password?': 'Avez-vous oublié votre mot de passe?',
  'Accedi e scarica i tuoi percorsi preferiti': 'Accédez et téléchargez vos itinéraires favoris',
  'Puoi anche condividerli con i tuoi compagni di viaggio': 'Vous pouvez également les partager avec vos compagnons de voyage',
  'Registrati': 'S\'inscrire',
  'Tracce scaricate': 'Traces téléchargées',
  'Utilizzo dati': 'Utilisation de données',
  'Registrazioni': 'Archives',
  'Photos': 'Photos',
  'Waypoint': 'Waypoint',
  "Nome": "Nom",
  "inserisci il tuo nome": "Insérez votre nom",
  "E' necessario inserire un nome": "Un nom doit être saisi",
  "Email": "Email",
  "E' necessario inserire una email": "Il est nécessaire d'insérer un email",
  "L'email inserita non è un'email valida": "L'e-mail est invalide",
  "Codice Fiscale": "Code fiscal",
  "Perché ti chiediamo il CF?": "Pourquoi vous demande-t-on le CF?",
  "Il codice fiscale inserito non è valido": "Le code fiscal n'est pas valide",
  "inserisci password": "Entrer le mot de passe",
  "E' necessario inserire la password": "Un mot de passe doit être saisi",
  "Conferma password": "Confirmez le mot de passe",
  "Ripeti password": "Répétez le mot de passe",
  "E' necessario confermare la password": "La confirmation du mot de passe est requise",
  "La conferma non corrisponde con la password inserita sopra": "La confirmation ne correspond pas au mot de passe saisi ci-dessus",
  "Cliccando su \"Registrati\" accetti la nostra": "En cliquant sur \"S'inscrire\", vous acceptez notre",
  "e i": "e i",
  "Termini e condizioni": "ermes et conditions",
  "Errore durante la registrazione dell'utente": "Erreur lors de l'enregistrement de l'utilisateur",
  "Registrazione in corso": "Inscription en cours",
  "Perchè ti chiediamo il Codice Fiscale?": "Pourquoi vous demande-t-on le Code Fiscal ?",
  "Se sei Socia/o CAI inserisci il tuo CF al momento della registrazione. Per te il download delle tappe del Sentiero Italia CAI sarà automaticamente gratuito!":
    "Si vous êtes membre CAI, inscrivez votre CF lors de votre inscription. Pour vous, le téléchargement des étapes du Sentiero Italia CAI sera automatiquement gratuit !",
  "Origine dell'immagine": "Origine de l'image",
  "Annulla": "Annuler",
  "Dalla libreria": "De la bibliothèque",
  "Scatta una foto": "Prendre une photo",
};
