// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.webmapp-photomodal-popover-element {
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modalphotos/popoverphoto/popoverphoto.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ","sourcesContent":[".webmapp-photomodal-popover-element{\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
