// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-status-filter ion-row {
  width: 100%;
}
wm-status-filter .chip-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
  max-height: 200px;
  overflow: auto;
}
wm-status-filter .chip-list .wm-status-filter-icn {
  height: 55%;
}
wm-status-filter .chip-list .wm-status-filter-icn * {
  height: 180%;
}
wm-status-filter .chip-list ion-chip {
  order: 0;
  flex: 1 1 auto;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
}
wm-status-filter .chip-list ion-chip ion-label {
  order: 0;
  flex: 1 1 auto;
  align-self: stretch;
}
wm-status-filter .chip-list ion-chip ion-icon,
wm-status-filter .chip-list ion-chip i {
  order: 0;
  flex: 0 1 auto;
  align-self: center;
}
wm-status-filter .chip-list ion-chip img {
  position: relative;
  height: 25px;
  display: block;
  margin-left: -7px;
  margin-top: -4px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/filters/status-filter/status-filter.component.scss"],"names":[],"mappings":"AACE;EACE,WAAA;AAAJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,8BAAA;EACA,sBAAA;EACA,oBAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AACI;EACE,WAAA;AACN;AAAM;EACE,YAAA;AAER;AACI;EACE,QAAA;EACA,cAAA;EACA,mBAAA;EAEA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,8BAAA;EACA,sBAAA;EACA,oBAAA;AAAN;AACM;EACE,QAAA;EACA,cAAA;EACA,mBAAA;AACR;AACM;;EAEE,QAAA;EACA,cAAA;EACA,kBAAA;AACR;AACM;EACE,kBAAA;EACA,YAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;AACR","sourcesContent":["wm-status-filter {\n  ion-row {\n    width: 100%;\n  }\n  .chip-list {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    align-content: stretch;\n    align-items: stretch;\n    max-height: 200px;\n    overflow: auto;\n    .wm-status-filter-icn {\n      height: 55%;\n      * {\n        height: 180%;\n      }\n    }\n    ion-chip {\n      order: 0;\n      flex: 1 1 auto;\n      align-self: stretch;\n\n      display: flex;\n      flex-direction: row;\n      flex-wrap: wrap;\n      justify-content: space-between;\n      align-content: stretch;\n      align-items: stretch;\n      ion-label {\n        order: 0;\n        flex: 1 1 auto;\n        align-self: stretch;\n      }\n      ion-icon,\n      i {\n        order: 0;\n        flex: 0 1 auto;\n        align-self: center;\n      }\n      img {\n        position: relative;\n        height: 25px;\n        display: block;\n        margin-left: -7px;\n        margin-top: -4px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
