// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-profile-user .wm-profile-user-container {
  --user-container-padding: 15px;
  --user-container-content-height: 64px;
  padding: var(--user-container-padding);
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}
wm-profile-user .wm-profile-user-container .wm-profile-user-avatar {
  display: block;
  width: var(--user-container-content-height);
  height: var(--user-container-content-height);
}
wm-profile-user .wm-profile-user-container .wm-profile-user-avatar.wm-profile-user-avatar-icon {
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--wm-color-light);
  background: rgba(var(--wm-color-secondary-rgb), 0.5);
}
wm-profile-user .wm-profile-user-container .wm-profile-user-header-container {
  display: flex;
  margin-left: 20px;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
wm-profile-user .wm-profile-user-container .wm-profile-user-header-container .wm-profile-user-name {
  margin: 0 0 3px;
}
wm-profile-user .wm-profile-user-container .wm-profile-user-header-container .wm-profile-user-email {
  font-size: var(--wm-font-sm);
  color: var(--wm-color-darkgrey);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/profile/profile-user/profile-user.component.scss"],"names":[],"mappings":"AACE;EACE,8BAAA;EACA,qCAAA;EAEA,sCAAA;EACA,aAAA;EACA,WAAA;EACA,2BAAA;EACA,mBAAA;AADJ;AAEI;EACE,cAAA;EACA,2CAAA;EACA,4CAAA;AAAN;AACM;EACE,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,4BAAA;EACA,oDAAA;AACR;AAEI;EACE,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,uBAAA;EACA,sBAAA;AAAN;AACM;EACE,eAAA;AACR;AACM;EACE,4BAAA;EACA,+BAAA;AACR","sourcesContent":["wm-profile-user{\n  .wm-profile-user-container {\n    --user-container-padding: 15px;\n    --user-container-content-height: 64px;\n\n    padding: var(--user-container-padding);\n    display: flex;\n    width: 100%;\n    justify-content: flex-start;\n    align-items: center;\n    .wm-profile-user-avatar {\n      display: block;\n      width: var(--user-container-content-height);\n      height: var(--user-container-content-height);\n      &.wm-profile-user-avatar-icon {\n        font-size: 30px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        color: var(--wm-color-light);\n        background: rgba(var(--wm-color-secondary-rgb), 0.5);\n      }\n    }\n    .wm-profile-user-header-container {\n      display: flex;\n      margin-left: 20px;\n      align-items: flex-start;\n      justify-content: center;\n      flex-direction: column;\n      .wm-profile-user-name {\n        margin: 0 0 3px;\n      }\n      .wm-profile-user-email {\n        font-size: var(--wm-font-sm);\n        color: var(--wm-color-darkgrey);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
