// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-tracks-box .wm-tracks-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
wm-tracks-box .wm-tracks-wrapper .wm-track-arrow {
  background: white;
  position: absolute;
  border-radius: 100%;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.0549019608);
  cursor: pointer;
  border: none;
  color: black;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 30px;
  padding: 0px;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
wm-tracks-box .wm-tracks-wrapper .left {
  left: 0;
}
wm-tracks-box .wm-tracks-wrapper .right {
  right: 0;
}
wm-tracks-box .wm-tracks-wrapper .wm-tracks-container::-webkit-scrollbar {
  display: none;
}
wm-tracks-box .wm-tracks-wrapper .wm-tracks-container {
  display: flex;
  overflow: auto;
}
wm-tracks-box .wm-tracks-wrapper .wm-tracks-container wm-box {
  margin-right: 15px;
  flex: 1;
  -webkit-flex: 1;
  text-align: center;
  min-width: 60%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/box/tracks-box/tracks-box.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,UAAA;AAAJ;AACI;EACE,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,mDAAA;EACA,eAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,qBAAA;EACA,qBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AACN;AACI;EACE,OAAA;AACN;AACI;EACE,QAAA;AACN;AAEI;EACE,aAAA;AAAN;AAEI;EACE,aAAA;EACA,cAAA;AAAN;AACM;EACE,kBAAA;EACA,OAAA;EACA,eAAA;EACA,kBAAA;EACA,cAAA;EACA,WAAA;AACR","sourcesContent":["wm-tracks-box {\n  .wm-tracks-wrapper {\n    position: relative;\n    display: flex;\n    align-items: center;\n    margin: 0;\n    padding: 0;\n    .wm-track-arrow {\n      background: white;\n      position: absolute;\n      border-radius: 100%;\n      box-shadow: 5px 5px 5px #0000000e;\n      cursor: pointer;\n      border: none;\n      color: black;\n      padding: 8px;\n      text-align: center;\n      text-decoration: none;\n      display: inline-block;\n      border-radius: 30px;\n      padding: 0px;\n      width: 30px;\n      height: 30px;\n      line-height: 30px;\n    }\n    .left {\n      left: 0;\n    }\n    .right {\n      right: 0;\n    }\n\n    .wm-tracks-container::-webkit-scrollbar {\n      display: none;\n    }\n    .wm-tracks-container {\n      display: flex;\n      overflow: auto;\n      wm-box {\n        margin-right: 15px;\n        flex: 1;\n        -webkit-flex: 1;\n        text-align: center;\n        min-width: 60%;\n        width: 100%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
