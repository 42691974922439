// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wm-select-filter ion-list {
  padding: 0 !important;
}
wm-select-filter .chip-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
  overflow: auto;
  max-height: 600px;
}
wm-select-filter .chip-list ion-chip {
  order: 0;
  flex: 1 1 auto;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
}
wm-select-filter .chip-list ion-chip ion-label {
  order: 0;
  flex: 1 1 auto;
  align-self: stretch;
}
wm-select-filter .chip-list ion-chip ion-icon,
wm-select-filter .chip-list ion-chip i {
  order: 0;
  flex: 0 1 auto;
  align-self: stretch;
}
wm-select-filter .chip-list ion-chip img {
  position: relative;
  height: 25px;
  display: block;
  margin-left: -7px;
  margin-top: -4px;
}

div svg {
  height: 200%;
}

ion-chip {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/filters/select-filter/select-filter.component.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;AAAJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,8BAAA;EACA,sBAAA;EACA,oBAAA;EACA,cAAA;EACA,iBAAA;AAAJ;AACI;EACE,QAAA;EACA,cAAA;EACA,mBAAA;EAEA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,8BAAA;EACA,sBAAA;EACA,oBAAA;AAAN;AACM;EACE,QAAA;EACA,cAAA;EACA,mBAAA;AACR;AACM;;EAEE,QAAA;EACA,cAAA;EACA,mBAAA;AACR;AACM;EACE,kBAAA;EACA,YAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;AACR;;AAME;EACE,YAAA;AAHJ;;AAMA;EACE,WAAA;AAHF","sourcesContent":["wm-select-filter {\n  ion-list {\n    padding: 0 !important;\n  }\n  .chip-list {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    align-content: stretch;\n    align-items: stretch;\n    overflow: auto;\n    max-height: 600px;\n    ion-chip {\n      order: 0;\n      flex: 1 1 auto;\n      align-self: stretch;\n\n      display: flex;\n      flex-direction: row;\n      flex-wrap: wrap;\n      justify-content: space-between;\n      align-content: stretch;\n      align-items: stretch;\n      ion-label {\n        order: 0;\n        flex: 1 1 auto;\n        align-self: stretch;\n      }\n      ion-icon,\n      i {\n        order: 0;\n        flex: 0 1 auto;\n        align-self: stretch;\n      }\n      img {\n        position: relative;\n        height: 25px;\n        display: block;\n        margin-left: -7px;\n        margin-top: -4px;\n      }\n    }\n  }\n}\n\ndiv {\n  svg {\n    height: 200%;\n  }\n}\nion-chip {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
